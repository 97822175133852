<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">工具软件定制开发</h1>
        <p data-caption-delay="100" class="wow fadeInRight">工具类软件开发服务及基于成熟产品的应用方案构建</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="StormEye测试执行管理工具" name="1">StormEye测试执行管理工具</el-tab-pane>
          <el-tab-pane label="研发辅助工具" name="2">研发辅助工具</el-tab-pane>
          <el-tab-pane label="CANoe扩展服务" name="3">CANoe扩展服务</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed?'solution-tab-fixed':''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <el-row>
              <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                <div class="normal-title">解决方案介绍</div>
                <div class="all-intro">
                  <div class="intro-detail">
                    <p>
                      StormEye是一款用于汽车电子测试领域的测试执行管理软件。产品适用于汽车电子网络测试、功能测试、仿真测试等领域，包含测试管理、测试执行、问题跟踪等内容。可有效简化工程师工作内容，提升工作效率。
                    </p>
                  </div>
                  <div class="intro-detail">
                    <p>产品分为桌面版StormEye Single与服务器版StormEye Server两种类型。</p>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                <img src="../../../assets/images/solution-toolsoft-stormeye-intro.png"
                     style="display: block;width: 90%;margin: 0 auto;" alt="">
              </el-col>
            </el-row>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions toolsoft-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in functionList1" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <i class="ri-macbook-line" style="font-size: 40px;margin-bottom: 20px;margin-top: 10px;"></i>
                      </div>
                      <div class="card-title">
                        <h1>{{ item.title }}</h1>
                      </div>
                      <div class="card-text">
                        <p style="text-align: left">{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <!-- 根据情况引入控件 -->
                <!--            <div class="swiper-pagination" slot="pagination"></div>-->
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title">优势亮点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span>简化操作</span>只需要维护参数文件，即可完成相关测试工作</li>
                <li><span>统一管理</span>统一测试执行出口，对接多类测试工具</li>
                <li><span>规范流程</span>打造测试管理闭环机制</li>
                <li><span>提升效率</span>简化测试操作、降低测试成本、规范测试流程</li>
                <li><span>按需定制</span>支持通过定制开发满足客户个性化业务需求</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="application-content">
                <div class="application-icon">
                  <img src="../../../assets/images/application-picture.svg" alt="">
                </div>
                <div class="application-text">
                  <p>网络测试/功能测试/仿真测试</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solutions/tool-01.png" style="width: 80%;height: 80%;border: 1px solid #ddd" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '2'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">服务介绍</div>
            <div class="all-intro">
              <div class="intro-text">
                <p>提供汽车电子电气设计、开发、测试、仿真过程中的各类辅助工具，为电子电气研发工作提质增效。</p>
              </div>
            </div>
          </div>
        </section>
        <section class="solution-intros wow fadeInRight" style="background: #e2eaf4">
          <div class="container">
            <div class="normal-title" style="width: 500px">StormDBConv_Diag诊断数据库转换工具</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text">
                    <p>快速实现诊断问卷调查表（Excel文件）与ODX、PDX间的格式转换。</p>
                  </div>
                  <div class="intro-text">
                    <p>支持Excel形式诊断调查问卷文件快速转换为ODX（-c、-d、-v v2.2.0）标准诊断数据库文件。支持文件数据格式检查，提供检查结果提醒。支持将转换的多个 ODX 文件打包成整车的 PDX
                      数据包，用于整车诊断。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title" style="width: 500px">StormDBConv_Com通信数据库转换工具</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text">
                    <p>快速实现通讯矩阵（Excel文件）与DBC、LDF、ARXML间的格式转换。
                      支持CAN/CANFD、J1939、LIN、以太网等协议，支持文件数据格式检查，支持整车路由表生成，支持整车矩阵和DBC文件导入导出，支持基于车型的文件管理，支持数据库文件比对，支持自定义属性定义。
                    </p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-intros wow fadeInRight" style="background: #E2EAF4">
          <div class="container">
            <div class="normal-title" style="width: 500px">StormIOConv I/O模型转换工具</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text">
                    <p>支持导入DBC/LDF/ARXML/Excel文件，快速实现Simulink中I/O建模，包含Checksum算法，实现I/O模型与总线接口的自动匹配。</p>
                  </div>
                  <div class="intro-text">
                    <p>工具可自动解析文件中的报文和信号层级，根据网络节点的Rx和Tx类型，进行模块布局和连线，每个信号具备自动命名且手动设定接口，针对数字类硬线信号，还包含取反模块，实现 0/1
                      的快速切换配置。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title" style="width: 500px">StormScenaGen场景泛化工具</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text">
                    <p>基于XML及泛化规则，快速生成动态场景XML文件，可导入VTD进行仿真业务操作。</p>
                  </div>
                  <div class="intro-text">
                    <p>
                      支持直接导入工具配套使用的场景库和用户配置参数两种方式生成XML文件；支持直接导入基于OpenScenario标准书写的场景元素表格和用户配置参数两种模式生成Xosc文件；支持生成道路的Xodr与Osgb文件。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-intros wow fadeInRight" style="background: #E2EAF4">
          <div class="container">
            <div class="normal-title" style="width: 300px">上位机配置工具</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text">
                    <p>配合电子电气开发/测试各阶段，配合控制器嵌入式软件定制开发上位机服务，提供可运行于Windows系统的操作工具，提供面向设备的管理端输入，与设备协同完成业务操作。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title">优势亮点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li>操作简单，效率高</li>
                <li>支持定制，可根据业务需要灵活扩展工具功能</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="network-solution-system-picture toolsoft-solution-system-picture wow fadeInRight">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <el-row>
                <el-col :span="24">
                  <div class="system-picture">
                    <img src="../../../assets/images/solutions/tool-02.png" alt="">
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '3'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <el-row>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="normal-title">服务介绍</div>
                <div class="all-intro">
                  <div class="intro-text">
                    <p>CANoe扩展服务使CANoe可以系统化、便捷化的与其他设备、工具、系统进行数据交换与互联互通，实现复杂场景的一键式测试，简化测试流程，提升测试效率。</p>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="intro-picture" style="text-align: center">
                  <img style="width: 80%" src="../../../assets/images/solution-canoe-intro.png" alt="">
                </div>
              </el-col>
            </el-row>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions canoe-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">服务内容</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in functionList2" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <i class="ri-settings-line" style="font-size: 40px;margin-bottom: 20px;margin-top: 10px;"></i>
                      </div>
                      <div class="card-title">
                        <h1>{{ item.title }}</h1>
                      </div>
                      <div class="card-text" style="text-align: left">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <!-- 根据情况引入控件 -->
                <!--            <div class="swiper-pagination" slot="pagination"></div>-->
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title">优势亮点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li>可将CANoe作为唯一操作平台，实现复杂业务逻辑的一键式调用</li>
                <li>降低测试工程师测试复杂度的同时，缩短测试周期本、提升工作效率</li>
                <li>支持定制开发，实现CANoe可与各类客户自研工具/设备对接</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
  import mainNav from "@/components/MainNav.vue";
  import mainFooter from "@/components/MainFooter.vue";
  import blueBg from "@/assets/images/solution-eletric-function-bg.png";
  import bzInfo from "@/components/BzInfo.vue";

  export default {
    name: "toolSoftware",
    components: {
      mainFooter,
      mainNav,
      bzInfo
    },
    data() {
      return {
        tabFixed: false,
        activeIndex: '2-7',
        blueBg: blueBg,
        activeProduct: '1',
        swiperOption: {
          spaceBetween: 10, // 图片之间的间距
          centeredSlides: false, // 居中还是从图1开始
          slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
          breakpointsInverse: true,
          breakpoints: {
            //当宽度大于等于1100
            1100: {
              slidesPerView: 4,
              spaceBetween: 10
            }
          },
          notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
          // loop: true, // 循环吗
          initialSlide: 0, // 从第几个开始
          autoplay: {
            delay: 5000, // 等5秒下一个
            disableOnInteraction: false // 中间滑动一下，取消自动吗？
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }, // 下按钮
          speed: 800, // 滑动时候动画的速度
          paginationClickable: true, // 下面按钮让点吗
          navigation: {
            prevEl: '.swiper-button-prev', // 左侧按钮
            nextEl: '.swiper-button-next' // 右侧按钮
          },
          // effect: 'fade', // 渐入效果
          watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
          watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
          // autoHeight: true  // 图片高度自适应
        },
        functionList1: [
          {
            title: "创建测试工程",
            content: "支持基于测试脚本模板进行变量管理，快速生成测试工程"
          },
          {
            title: "配置测试队列",
            content: "支持动态配置测试队列并启动执行任务"
          },
          {
            title: "对接测试工具",
            content: "包括CANoe / vTESTstudio / TTworkbench / ECU-TEST / VeriStand / TestStand/ dSPACE / LabVIEW / 客户自研工具等"
          },
          {
            title: "自定义测试报告",
            content: "实时显示测试结果，支持测试问题记录与过程跟踪"
          },
          {
            title: "过程跟踪",
            content: "提供系统的用户管理、部门管理、岗位管理、角色管理、权限管理、菜单管理、菜单授权、字典配置等配置管理"
          },
          {
            title: "协同测试",
            content: "支持多角色、多角色与设备跨地域协同工作（服务器版）"
          },
          {
            title: "测试管理",
            content: "支持实验室管理、设备管理、测试用例管理、测试需求管理、测试计划管理、测试样件管理、测试执行管理、测试问题管理闭环管理机制（服务器版）"
          },
          {
            title: "远程测试",
            content: "支持远程自动化测试与多设备并行测试（服务器版）"
          },
          {
            title: "远程调试",
            content: "系统用户可通过系统远程控制上位机进行调试解决问题（服务器版）"
          },
          {
            title: "集中监管",
            content: "提供不同角色工作门户，按需定制展示内容，提供中控大屏进行集中监管（服务器版）"
          },
        ],
        functionList2: [
          {
            title: "硬件对接",
            content: "在测试用例中对接电源、串口、ADB的命令控制等内容，将测试相关的硬件功能集成到CANoe的测试能力里，扩展CANoe的测试范围"
          },
          {
            title: "工具对接",
            content: "通过CANoe调用NI、dSPACE或用户自研等专业工具执行多维度的测试功能，通过业务参数传递业务需求及执行结果，实现业务联动"
          },
          {
            title: "系统/服务对接",
            content: "与各类业务管理系统、云平台等进行服务对接。也可通过调用其他中间件或Windows服务，执行外部服务的相关功能"
          },
          {
            title: "数据处理",
            content: "对传感器、设备或其他数据源采集的数据进行二次加工,包括数据清洗、数据转换、数据存储、数据分析等，满足不同技术、业务连接需要"
          },
          {
            title: "后台服务",
            content: "将CANoe功能封装为中间件或Windows服务。这些组件通过后台运行的方式处理数据和执行任务，可提高系统的可靠性和稳定性"
          },
          {
            title: "逻辑编排",
            content: "支持各类业务场景的逻辑编排，可基于场景测试时序队列，实现跨工具、跨系统、跨业务的流程测试，实现基于业务逻辑的场景编排与业务执行"
          },
        ],
        chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      }
    },
    mounted() {
      document.title = "工具软件定制开发 - 解决方案 - 东信创智"
      new this.$wow.WOW().init()
      window.addEventListener("scroll", this.handleScroll)
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      linkUrl(url) {
        window.open(url, '_blank') // 在新窗口打开外链接
        // window.location.href =this.indexro;  //在本页面打开外部链接
      },
      handleScroll() {
        if (document.documentElement.clientWidth > 1100) {
          let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
          if (top < 0 && top > -630) {
            this.tabFixed = !this.tabFixed;
          } else if (document.documentElement.scrollTop > 630) {
            this.tabFixed = true;
          }
        } else {
          let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
          if (top < 0 && top > -250) {
            this.tabFixed = !this.tabFixed;
          } else if (document.documentElement.scrollTop > 250) {
            this.tabFixed = true;
          }
        }
      },
      getTabFixedClass() {
        if (document.documentElement.clientWidth < 1100) {
          return 'sw-tab-title-mobile-fixed'
        } else {
          return 'sw-tab-title-fixed'
        }
      },
      handleTabClick() {
        if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
          document.documentElement.scrollTop = 630
        } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
          document.documentElement.scrollTop = 250
        }
      }
    }
  }
</script>
